import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileImage } from './profile-image';
import './profile-images-group.scss';
import Tooltip from '../tooltip';
import { User } from '@common/types/objects';

interface Props {
  users: User[];
  size?: number;
  limit?: number;
  showTooltip?: boolean;
  tooltipKey?: string;
  onClick?: (user: User) => void;
  onShowAll?: () => void;
  renderTooltip?: (user: User) => React.ReactNode;
}

export default function ProfileImagesGroup({
  users,
  size = 30,
  limit = 3,
  onClick,
  onShowAll,
  renderTooltip,
}: Props) {
  const { t } = useTranslation();

  const renderProfile = (user: User, className: string, index: number) => {

    const imageProps = {
      className,
      key: user.id,
      size,
      user,
      onClick: () => onClick?.(user),
    };

    if (renderTooltip) {
      const tooltip = renderTooltip?.(user);
      return (
        <Tooltip title={tooltip} key={`${user.id}-${index}`}>
          <div className="tw-relative">
            <ProfileImage {...imageProps} />
          </div>
        </Tooltip>
      );
    }

    return (
      <ProfileImage {...imageProps} />
    );
  };

  const hasMore = users.length > limit;

  return (
    <div className="ProfileImagesGroup">
      {
        users.slice(0, limit).map((user: User, i: number) => {
          const className = i > 0 ? 'overlapping' : '';
          return renderProfile(user, className, i);
        })
      }
      {hasMore && (
        <Tooltip title={t('social:members_show_all')}>
          <div
            className={`
              tw-bg-gray-light-50 tw-rounded-full tw-border tw-border-white tw-cursor-pointer
              tw-flex tw-items-center tw-justify-center tw-ml-[-8px] tw-z-10`}
            style={{
              width: `${size}px`,
              height: `${size}px`,
            }}
            onClick={onShowAll}
          >
            <span>+{users.length - limit}</span>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
